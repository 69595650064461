import * as React from "react"

import { GlobalLayout } from "@/components/GlobalLayout"
import { Head } from "@/components/Head"
import { SectionWrap } from "@/components/sections/SectionWrap"
import { PageMusicsArchiveQuery } from "@root/types/graphql-types"
import { PaginationPageContext } from "@/types/PageContext"
import { Pagination } from "@/components/Pagination"
import { useImage } from "@/hooks/useImage"
import { TitleWithBgImg } from "@/components/TitleWithBgImg"
import { MusicCard } from "@/components/Cards/GoodsCards"

interface Props {
    data: PageMusicsArchiveQuery
    pageContext: PaginationPageContext
}

export const Musics: React.FC<Props> = ({ data, pageContext }) => {
    const { getImageProps, Image } = useImage()
    return (
        <GlobalLayout
            Head={<Head title="musics" description="楽曲一覧ページ" />}
        >
            <SectionWrap
                Title={
                    <TitleWithBgImg
                        title="MUSICS INFO"
                        BgImage={
                            <Image
                                {...getImageProps(
                                    data.contentfulSite?.siteHeroImage
                                        ?.gatsbyImageData
                                )}
                            />
                        }
                    />
                }
            >
                <div className="p-menus p-section__content">
                    {data.allContentfulMusics?.edges ? (
                        data.allContentfulMusics.edges.map(({ node }) => (
                            <article className="p-menus__content">
                                <MusicCard
                                    title={node.musicTitle || ""}
                                    href={`/musics/${node.slug}`}
                                    Image={
                                        <Image
                                            {...getImageProps(
                                                node.musicCDImage
                                                    ?.gatsbyImageData
                                            )}
                                        />
                                    }
                                    releaseDate={node.musicReleaseDate}
                                    musicType={node.musicType || "unknown"}
                                    joiningArtistName={
                                        node.musicJoiningArtistName || undefined
                                    }
                                    className="u-width-100"
                                />
                            </article>
                        ))
                    ) : (
                        <p>CDが登録されていません</p>
                    )}
                </div>
                <Pagination
                    currentPage={pageContext.currentPage}
                    numPages={pageContext.numberOfPages}
                    basePath="/musics"
                    className="p-section__pagination"
                />
            </SectionWrap>
        </GlobalLayout>
    )
}
