import { graphql } from 'gatsby'
import { Musics } from '@/components/pages/Musics'

export default Musics

export const query = graphql`
    query PageMusicsArchive($skip: Int!, $limit: Int!) {
        contentfulSite {
            siteHeroImage {
                gatsbyImageData(resizingBehavior: FILL)
            }
        }
        allContentfulMusics(
            sort: { fields: musicReleaseDate, order: DESC }
            skip: $skip
            limit: $limit
            filter: { slug: { ne: "dummy" } }
        ) {
            edges {
                node {
                    id
                    slug
                    musicType
                    musicTitle
                    musicReleaseDate
                    musicCDImage {
                        gatsbyImageData(
                            resizingBehavior: FILL
                            height: 300
                            width: 300
                        )
                    }
                    musicJoiningArtistName
                }
            }
        }
    }
`
